@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

:root {
  --assa-blue: #28A0CF;
  
}

@layer base {


  html {
    scroll-behavior: smooth;
    background-color: var(--assa-blue);
  }

  html, body {
      font-family: "Open Sans", sans-serif;
  }
  .app {
    width: 100vw;
    height: 100vh;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 1em;
  }

  #qr-shaded-region{
    display: none;
  }

  button.arrow svg {
    fill: var(--assa-blue);
  }
  button.arrow:hover svg {
    fill: white;
  }
  button.arrow.blue svg {
    fill: white;
  }
  button.arrow.blue:hover svg {
    fill: var(--assa-blue);
  }

  select {
    appearance: none;
    background-image: url('../public/images/down-arrow.svg');
    background-position: 95% center;
    background-size: 10px 10px;
    background-repeat: no-repeat;
  }
}
